import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import Swal from 'sweetalert2'
import { Translate, I18n } from 'react-i18nify-lite';

import { chartParser } from '../utils/chartjs-configs';
import { DEFAULT_AVERAGES, DEFAULT_CHART_DATA, EMPTY_PLAYLIST, SAVED_TRACKS } from '../utils/playlist-states';
import { FeatureLabel, SortButton } from '../components/playlist';
import { showPlaylistData, toUpCase } from '../utils/playlist-data-utils';
import { sortBy, applyChanges, resetSort } from '../utils/sort-by-utils';
import Loader from '../components/loader';
import Coffee from '../components/coffee';
import playlistService from '../services/playlists';
import profileService from '../services/profile';

import '../styles/app.css';
import '../styles/playlists.css';
import LocaleSelector from '../components/localeSelector';

export default function Playlists() {
  const [playlists, setPlaylists] = useState([]);
  const [loadingPlaylists, setLoadingPlaylists] = useState(true);
  const [loadingTracks, setLoadingTracks] = useState(false);
  const [totalSavedTracks, setTotalSavedTracks] = useState(true)
  const [currentPlaylist, setCurrentPlaylist] = useState(EMPTY_PLAYLIST);
  const [averages, setAverages] = useState(DEFAULT_AVERAGES);
  const [showPopularity, setShowPopularity] = useState(true);
  const [showTempo, setShowTempo] = useState(true);
  const [showDance, setShowDance] = useState(true);
  const [showEnergy, setShowEnergy] = useState(true);
  const [showHappy, setShowHappy] = useState(true);
  const [chartData, setChartData] = useState(DEFAULT_CHART_DATA);
  const [lastSelectedFeature, setLastSelectedFeature] = useState(null);

  useEffect(() => {
    getPlaylists();
    getTotalSavedTracks();
  }, [])

  const getPlaylists = async () => {
    setPlaylists(await playlistService.getAll());
    setLoadingPlaylists(false);
  }

  const getTotalSavedTracks = async () => {
    const { total } = await profileService.getSavedTracksAmount();
    setTotalSavedTracks(total);
    setLoadingPlaylists(false);
  }

  const showPlaylistStates = {
    setLoadingTracks,
    setCurrentPlaylist,
    setChartData,
    setAverages
  }

  const applyChangesSequence = async () => {
    await applyChanges({
      sortedPlaylist: chartData.sortedPlaylist,
      currentPlaylist
    });

    Swal.fire({
      title: I18n.t('application.applied'),
      background: '#16161d',
      color: '#ffffff',
      icon: 'success'
    });
    // This reloads after the patch request is successful
    showPlaylistData({ playlist: currentPlaylist, ...showPlaylistStates })
    // This resets sortedPlaylist, so the sorting buttons reappear
    setChartData((prevData) => ({ ...prevData, sortedPlaylist: undefined }))
  };

  const resetSortStates = {
    setChartData,
    chartData
  }

  const previewSort = (feature, sortDesc = false) => {
    const updatedData = sortBy(feature, chartData.unsortedPlaylist, sortDesc)
    // setChartData will update the chart showing the preview
    setChartData((prevData) => ({ ...prevData, ...updatedData }));

    // setLastSelectedFeature will be used to display the feature name in the Apply button
    feature = feature === 'valence' ? 'happiness' : feature;
    setLastSelectedFeature(toUpCase(feature));
  };

  return (
    <div className='container'>
      <div className='flex space-between mb-10'>
        <span className='flex items-center'>
          <Link to='/logout'>
            <button className={'styled-button logout-button'}>
              <Translate value='application.logout' style={{ textTransform: 'capitalize' }} />
            </button>
          </Link>
          <Coffee />
        </span>
        <span className='flex items-center'>
          <LocaleSelector />
          <small style={{ marginLeft: '10px', color: 'gray' }}>v1.3🍓</small>
        </span>
      </div>
      {!loadingTracks && currentPlaylist.name === undefined && loadingPlaylists && <Loader />}
      {!loadingTracks && currentPlaylist.name === undefined && !loadingPlaylists && (
        <div style={{ textAlign: 'justify', margin: '20px auto' }}>
          <h1>
            <Translate value='playlists.title' style={{ textTransform: 'capitalize' }} />
          </h1>
          <article className='playlists-container'>
            <button className='styled-button playlist-button'
              onClick={() => showPlaylistData({ playlist: { id: null, name: SAVED_TRACKS }, ...showPlaylistStates })}>
              <Translate value='playlists.savedTracks' style={{ textTransform: 'capitalize' }} />&nbsp;<small>[{totalSavedTracks}]</small>
            </button>
            {playlists.map(playlist => (
              <button className='styled-button playlist-button'
                onClick={() => showPlaylistData({ playlist, ...showPlaylistStates })}
                key={playlist.id}>
                {playlist.name} <small>[{playlist.tracks.total}]</small>
              </button>
            ))}
          </article>
        </div>
      )}
      {loadingTracks && <Loader />}
      {currentPlaylist.name !== undefined && !loadingTracks && (
        <div className='playlist-details-container'>
          <h1 className='title'>
            <span>
              {currentPlaylist.name}&nbsp;
              {currentPlaylist.name === SAVED_TRACKS && <small style={{ fontSize: '50%' }}>[Read Only]</small>}
            </span>
            <button className='styled-button'
              onClick={() => setCurrentPlaylist(EMPTY_PLAYLIST)}>
              <Translate value='playlists.goBack' />
            </button>
          </h1>

          {(chartData.sortedPlaylist === undefined && currentPlaylist.name !== SAVED_TRACKS) && (
            <article className='sorting-buttons-container'>
              <span className='subgroup-buttons-container'>
                <SortButton humanName={'Popularity ASC'}
                  onClick={() => { previewSort('popularity') }} />
                <SortButton humanName={'Popularity DESC'}
                  onClick={() => { previewSort('popularity', true) }} />
              </span>
              <span className='subgroup-buttons-container'>
                <SortButton humanName={'Tempo ASC'}
                  onClick={() => { previewSort('tempo') }} />
                <SortButton humanName={'Tempo DESC'}
                  onClick={() => { previewSort('tempo', true) }} />
              </span>
              <span className='subgroup-buttons-container'>
                <SortButton humanName={'Danceability ASC'}
                  onClick={() => { previewSort('danceability') }} />
                <SortButton humanName={'Danceability DESC'}
                  onClick={() => { previewSort('danceability', true) }} />
              </span>
              <span className='subgroup-buttons-container'>
                <SortButton humanName={'Energy ASC'}
                  onClick={() => { previewSort('energy') }} />
                <SortButton humanName={'Energy DESC'}
                  onClick={() => { previewSort('energy', true) }} />
              </span>
              <span className='subgroup-buttons-container'>
                <SortButton humanName={'Happiness ASC'}
                  onClick={() => { previewSort('valence') }} />
                <SortButton humanName={'Happiness DESC'}
                  onClick={() => { previewSort('valence', true) }} />
              </span>
            </article>
          )}
          {chartData.sortedPlaylist !== undefined && (
            <article className='apply-buttons-container'>
              <button className='styled-button'
                onClick={() => resetSort(resetSortStates)}>
                <Translate value='application.cancel' />
              </button>
              <button className='styled-button apply-changes-button'
                onClick={async () => await applyChangesSequence()}>
                <Translate value='application.apply' />!&nbsp;
                [<Translate value={`playlists.${lastSelectedFeature.toLowerCase()}`} />]
              </button>
            </article>)}
          <Line className='chart-js'
            data={chartParser(showPopularity, showTempo, showDance, showEnergy, showHappy, chartData)}
            options={{ scales: { x: { display: false } } }} />
          <hr className='chart-features-ruler' />
          <article className='features-container'>
            <FeatureLabel showFeature={showPopularity} setShowFeature={setShowPopularity}
              featureName="Popularity" featureValue={`${averages.popularity}%`} featureEmoji="✨" />
            <FeatureLabel showFeature={showTempo} setShowFeature={setShowTempo}
              featureName="Tempo" featureValue={`${averages.tempo}BPM`} featureEmoji="🎵" />
            <FeatureLabel showFeature={showDance} setShowFeature={setShowDance}
              featureName="Dance" featureValue={`${averages.dance}%`} featureEmoji="🕺🏻" />
            <FeatureLabel showFeature={showEnergy} setShowFeature={setShowEnergy}
              featureName="Energy" featureValue={`${averages.energy}%`} featureEmoji="⚡️" />
            <FeatureLabel showFeature={showHappy} setShowFeature={setShowHappy}
              featureName="Happy" featureValue={`${averages.happy}%`} featureEmoji="😃" />
          </article>
        </div>
      )}
    </div>
  );
}
