import { Translate } from 'react-i18nify-lite';

export const FeatureLabel = (props) => {
  const { showFeature, setShowFeature, featureName, featureValue, featureEmoji } = props;
  return (<small
    className={`feature-data ${featureName.toLowerCase()} ${showFeature ? "" : "hidden"}`}
    onClick={() => setShowFeature(prevShowFeature => !prevShowFeature)}>
    <Translate value={`playlists.${featureName.toLowerCase()}`} />: {featureValue}{featureEmoji}
  </small>)
};

export const SortButton = (props) => {
  const [feature, direction] = props.humanName.split(' ');

  return (
    <button className='sorting-button' onClick={props.onClick}>
      <Translate value={`playlists.${feature.toLowerCase()}`} />&nbsp;
      <small style={{ fontSize: '65%', fontWeight: 'bolder' }}>
        [{direction}]
      </small>
    </button>
  )
};
