export const esMX = {
  application: {
    title: "Sortfella",
    description: "Ordena tus playlists de manera sencilla y rápida",
    logout: 'Cerrar sesión',
    loader: 'Cargando, por favor espera...',
    cancel: 'Cancelar',
    apply: 'Aplicar cambios',
    applied: 'Ordenamiento aplicado!',
  },
  login: {
    button: 'Inicia sesión con Spotify'
  },
  playlists: {
    title: 'Tus playlists',
    goBack: 'Regresar a tus playlists',
    savedTracks: 'Tus me gusta',
    popularity: 'Popularidad',
    tempo: 'Tempo',
    dance: 'Danzabilidad',
    danceability: 'Danzabilidad',
    energy: 'Energía',
    happy: 'Alegría',
    happiness: 'Alegría',
  }
};
