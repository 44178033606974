import React from 'react';
import authService from '../services/auth';
import { Translate } from 'react-i18nify-lite';

import '../styles/app.css';
import '../styles/login.css';
import logo from '../assets/dogfella.jpeg';

export default function Login() {

  const redirectToSpotifyLogin = () => {
    const spotifyLogin = authService.generateLogin();
    // This needs to redirect out the main domain
    // i.e.:`https://accounts.spotify.com/authorize?${searchParams}`
    window.location.href = spotifyLogin;
  };

  return (
    <div className='container text-center'>
      <figure style={{ margin: '5% auto 10%' }}>
        <img src={logo} className='app-logo' alt='logo' />
      </figure>
      <h1>Sortfella</h1>
      <button className='login-button' onClick={redirectToSpotifyLogin}>
        <Translate value='login.button' />
      </button>
    </div>
  )
}
