import BaseService from './base';

class ProfileService extends BaseService {

  async getMe() {
    const { data } = await this.request('/me');

    return data;
  }

  async getSavedTracksAmount() {
    const { data } = await this.request('/me/tracks');

    return data;
  }

  async getSavedTracks() {
    const tracks = [];
    let queryParams = '?limit=50';
    do {
      const { data } = await this.request(`/me/tracks${queryParams}`);
      tracks.push(...data.items);
      queryParams = data.next ? new URL(data.next).search : '';
    } while (queryParams);

    return tracks;
  }
}

const profileService = new ProfileService();
export default profileService;
