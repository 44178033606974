import BaseService from './base';

class PlaylistService extends BaseService {
  async getAll() {
    const playlists = [];
    let queryParams = '';
    do {
      const { data } = await this.request(`/me/playlists${queryParams}`);
      playlists.push(...data.items);
      queryParams = data.next ? new URL(data.next).search : '';
    } while (queryParams);
    // order data alphabetically
    const response = playlists.sort((a, b) => a.name.localeCompare(b.name));

    return response;
  }

  async getTracks(playlistId) {
    const tracks = []
    let queryParams = '';
    do {
      const { data } = await this.request(`/playlists/${playlistId}/tracks${queryParams}`);
      tracks.push(...data.items);
      queryParams = data.next ? new URL(data.next).search : '';
    } while (queryParams);

    return tracks;
  }

  async updatePlaylist(playlistId, trackUris) {
    const body = { uris: trackUris };
    const { data } = await this.put(`/playlists/${playlistId}/tracks`, body);

    return data;
  }

  async rebuildPlaylist(playlistId, trackUris) {
    const body = { uris: trackUris };
    const { data } = await this.post(`/playlists/${playlistId}/tracks`, body);

    return data;
  }
}

const playlistService = new PlaylistService();
export default playlistService;
