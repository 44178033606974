export const SAVED_TRACKS = 'Saved Tracks';

export const EMPTY_PLAYLIST = {
  id: undefined,
  name: undefined
};

export const DEFAULT_AVERAGES = {
  popularity: 0,
  tempo: 0,
  dance: 0,
  energy: 0,
  happy: 0
};

export const DEFAULT_CHART_DATA = {
  unsortedPlaylist: undefined,
  sortedPlaylist: undefined,
  tracksNamesArr: [],
  popularityArr: [],
  tempoArr: [],
  danceArr: [],
  energyArr: [],
  happyArr: [],
}