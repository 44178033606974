import {
  Chart as ChartJS, LineElement, PointElement, LinearScale, Tooltip, CategoryScale,
} from 'chart.js';

ChartJS.register(LineElement, PointElement, LinearScale, Tooltip, CategoryScale);

const formDataSet = (data, borderColor, label = '%') => ({
  data,
  borderColor,
  backgroundColor: borderColor,
  label,
  tension: 0.2,
});

const chartColors = {
  white: 'rgba(255, 255, 255)',
  green: 'rgba(75, 192, 192)',
  red: 'rgba(255, 99, 132)',
  yellow: 'rgba(255, 206, 86)',
  blue: 'rgba(54, 162, 235)',
  purple: 'rgba(153, 102, 255)'
};

export const chartParser = (showPopularity, showTempo, showDance, showEnergy, showHappy, chartData) => {
  const { popularityArr, tempoArr, danceArr, energyArr, happyArr, tracksNamesArr } = chartData;
  const datasets = [];
  if (showPopularity) datasets.push(formDataSet(popularityArr, chartColors.white, 'Popularity %'))
  if (showTempo) datasets.push(formDataSet(tempoArr, chartColors.green, 'BPM'))
  if (showDance) datasets.push(formDataSet(danceArr, chartColors.red, 'Dance %'))
  if (showEnergy) datasets.push(formDataSet(energyArr, chartColors.blue, 'Energy %'))
  if (showHappy) datasets.push(formDataSet(happyArr, chartColors.yellow, 'Happy %'))

  return {
    datasets,
    labels: tracksNamesArr
  };
}