import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import profileService from '../services/profile';
import Loader from '../components/loader';

import '../styles/app.css';
import '../styles/profile.css';

export default function Profile() {
  const [loading, setLoading] = useState(true);
  const [profileResponse, setProfileResponse] = useState({
    display_name: '',
    email: '',
    images: [{ url: '' }]
  });

  useEffect(() => {
    getMe();
  }, []);

  const getMe = async () => {
    setProfileResponse(await profileService.getMe());
    setLoading(false);
  }

  const ProfileSummary = () => (
    <div className='text-center'>
      <article>
        <h1 className='margin-0'>
          Welcome, {profileResponse.display_name}!
        </h1>
        <small>({profileResponse.email})</small>
      </article>
      <figure className='profile-picture-container'>
        <img src={profileResponse.images[1].url} alt='profile' />
      </figure>
    </div>
  )

  return (
    <div className='container text-left'>
      <Link to='/logout'><button className={'logout-button'}>Log Out</button></Link>
      <Link to='/playlists'><button>My Playlists</button></Link>
      {loading ? <Loader /> : <ProfileSummary />}
    </div>
  );
}
