import axios from 'axios';
import authServices from '../services/auth';

const BASE_API = 'https://api.spotify.com/v1';

class BaseService {

  async request(endpoint, afterRefresh = false) {
    try {
      return await axios.get(`${BASE_API}${endpoint}`, this._options());
    } catch (err) {
      if (afterRefresh) return console.error('Refreshing didn\'t work :(');

      return await authServices._consumeRefreshToken(endpoint)
    }
  };
  async put(endpoint, body) {
    try {
      return await axios.put(`${BASE_API}${endpoint}`, body, this._options());
    } catch (err) {
      console.error('Error updating your playlist, sorry :(', err);
    }
  };

  async post(endpoint, body) {
    try {
      return await axios.post(`${BASE_API}${endpoint}`, body, this._options());
    } catch (err) {
      console.error('Error rebuilding your playlist, sorry :(', err);
    }
  };

  _options() {
    const access_token = localStorage.getItem('access_token');

    return { headers: { 'Authorization': `Bearer ${access_token}` } };
  };
}

export default BaseService;