import axios from 'axios';
import baseService from './base';

const STATE_KEY = 'spotify_auth_state';
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET; // Your secret
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID; // Your client id
const REDIRECT_URI = `${process.env.REACT_APP_REDIRECT_URI}/callback`; // Your redirect uri
const AUTH_BASE_URL = 'https://accounts.spotify.com/api/token';

class AuthService {

  generateLogin() {
    const state = this._generateRandomString(16);
    const scope = 'user-read-private user-read-email user-library-read playlist-modify-public playlist-modify-private';
    localStorage.setItem(STATE_KEY, state);
    const searchParams = new URLSearchParams({
      scope, state, response_type: 'code', client_id: CLIENT_ID, redirect_uri: REDIRECT_URI
    })

    return `https://accounts.spotify.com/authorize?${searchParams}`;
  }

  async callBack(request) {
    try {
      const { requestBody, config } = this._generateSpotifyTokenUrl(request);
      const { data } = await axios.post(AUTH_BASE_URL, requestBody, config);
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('refresh_token', data.refresh_token);
      localStorage.setItem('loggedIn', true);
      return true
    }
    catch (err) {
      console.error('Callback error', err);
      return false;
    }
  }

  _generateRandomString(length) {
    var text = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

  _generateSpotifyTokenUrl(request) {
    const code = this._validateAuthenticity(request);
    const requestBody = new URLSearchParams({
      code, redirect_uri: REDIRECT_URI, grant_type: 'authorization_code'
    });
    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`
      }
    };
    return { requestBody, config };
  }

  _validateAuthenticity(request) {
    const urlParams = new URL(request.url).searchParams;
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const storedState = localStorage.getItem(STATE_KEY);
    if (!code) throw new Error('no_code');
    if (state !== storedState) throw new Error('state_mismatch');

    return code;
  }

  async _consumeRefreshToken(endpoint) {
    try {
      const refresh_token = localStorage.getItem('refresh_token');
      const requestBody = new URLSearchParams({
        refresh_token, grant_type: 'refresh_token'
      });
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Basic ${btoa(`${CLIENT_ID}:${CLIENT_SECRET}`)}`
        }
      };
      const { data } = await axios.post(AUTH_BASE_URL, requestBody, config);
      localStorage.setItem('access_token', data.access_token);
      localStorage.setItem('loggedIn', true);
      return await new baseService().request(endpoint, true)
    }
    catch (err) {
      localStorage.setItem('loggedIn', false);
      console.error(`There was an error refreshing`, err);
    };
  }
}

const authService = new AuthService();
export default authService;
