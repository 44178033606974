import { I18n } from 'react-i18nify-lite';

export default function LocaleSelector() {

  const setLocale = (locale) => {
    localStorage.setItem('locale', locale);
    I18n.setLocale(locale);
  }

  return (
    <span className='flex items-center'>
      <button className='styled-button' onClick={() => setLocale('esMX')}>
        Español
      </button>
      <button className='styled-button' onClick={() => setLocale('enUS')}>
        English
      </button>
    </span>
  )
};
