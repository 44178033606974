import {
  createBrowserRouter,
  redirect,
  RouterProvider,
} from 'react-router-dom';
import loginPage from '../src/views/Login';
import profilePage from '../src/views/Profile';
import playlistsPage from '../src/views/Playlists';
import authService from './services/auth';

import { I18n } from 'react-i18nify-lite';
import i18Strings from './assets/i18n/index';

const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    Component: loginPage,
    loader: rootLoader,
    children: [
      {
        path: '/callback',
        loader: callbackLoader
      }
    ]
  },
  {
    path: '/profile',
    Component: profilePage,
    loader: protectedLoader
  },
  {
    path: '/playlists',
    Component: playlistsPage,
    loader: protectedLoader
  },

  {
    path: 'logout',
    loader: logoutLoader
  }
])

function rootLoader() {
  if (!loggedIn()) return null;

  return redirect('/playlists');
}

function protectedLoader() {
  if (loggedIn()) return null;

  return redirect('/');
}

async function callbackLoader({ request }) {
  await authService.callBack(request);

  return redirect('/playlists');
}

function logoutLoader() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('spotify_auth_state');

  return redirect('/');
}

function loggedIn() {
  return JSON.parse(localStorage.getItem('loggedIn'))
};

function userLocale() {
  const localeValue = localStorage.getItem('locale');
  if (!localeValue) localStorage.setItem('locale', 'enUS');

  return localeValue || 'enUS';
}


export default function App() {

  I18n.setTranslations(i18Strings());
  I18n.setLocale(userLocale());

  return (
    <RouterProvider
      router={router}
    />
  );
}
