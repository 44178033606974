import BaseService from './base';

class TrackService extends BaseService {

  // The endpoint only allows 100 tracks at a time
  // So we need to split into chunks of 100
  async getBulkFeatures(tracksIds) {
    const trackIdsChunks = [];
    while (tracksIds.length) {
      trackIdsChunks.push(tracksIds.splice(0, 100));
    }

    const audioFeatures = [];
    for (const chunk of trackIdsChunks) {
      const parsedIds = new URLSearchParams({ ids: chunk }, { arrayFormat: 'comma' });
      const { data } = await this.request(`/audio-features/?${parsedIds}`);
      audioFeatures.push(...data.audio_features);
    }

    return audioFeatures
  }
}

const trackService = new TrackService();
export default trackService;
