export const enUS = {
  application: {
    title: "Sortfella",
    description: "Order your playlists easily and quickly",
    logout: 'Log out',
    loader: 'Loading, please wait...',
    cancel: 'Cancel',
    apply: 'Apply changes',
    applied: 'Sorting applied!',
  },
  login: {
    button: 'Log in with Spotify'
  },
  playlists: {
    title: 'Your playlists',
    goBack: 'Go back to playlists',
    savedTracks: 'Saved tracks',
    popularity: 'Popularity',
    tempo: 'Tempo',
    dance: 'Dance',
    danceability: 'Danceability',
    energy: 'Energy',
    happy: 'Happy',
    happiness: 'Happiness',
  }
};
