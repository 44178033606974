import playlistService from '../services/playlists';
import profileService from '../services/profile';
import trackService from '../services/track';

export const toUpCase = (text) => text.charAt(0).toUpperCase() + text.slice(1);

export const showPlaylistData = async (params) => {
  const { playlist } = params;
  const { setLoadingTracks, setCurrentPlaylist, setChartData, setAverages } = params;

  setLoadingTracks(true);
  const playlistData = await getPlaylistData(playlist, setAverages);
  setLoadingTracks(false);
  setCurrentPlaylist(playlist);

  setChartData((prevChartData) => ({
    sortedPlaylist: undefined,
    ...prevChartData,
    ...playlistData
  }));
}

const getPlaylistData = async ({ id: playlistId }, setAverages) => {
  const playlistDetails = await getTracks(playlistId);
  const chartDataResponse = await getAudioFeatures(playlistDetails);
  const { popularityArr, tempoArr, danceArr, energyArr, happyArr } = chartDataResponse;
  setAverages({
    popularity: calculateAverage(popularityArr, 1),
    tempo: calculateAverage(tempoArr, 1),
    dance: calculateAverage(danceArr),
    energy: calculateAverage(energyArr),
    happy: calculateAverage(happyArr),
  });

  return {
    sortedPlaylist: undefined, // reset state!
    ...chartDataResponse
  };
}

const getTracks = async (playlistId) => {
  if (playlistId) return await playlistService.getTracks(playlistId);

  return await profileService.getSavedTracks();
};

const getAudioFeatures = async (tracksGeneralData) => {
  const tracksIds = tracksGeneralData.map(({ track }) => track.id);
  const tracksFeatures = await trackService.getBulkFeatures(tracksIds);

  return getCurrentSort(tracksGeneralData, tracksFeatures);
}

const calculateAverage = (tracksArray) => (
  Math.round(
    tracksArray.reduce((sum, value) => sum + value, 0) / tracksArray.length
  )
)

const getCurrentSort = (tracksGeneralData, tracksFeatures) => {

  const [tracksNamesArr, tracksPopularity, trackUris] = tracksGeneralData.reduce(
    (accumulator, { track }) => {
      accumulator[0].push(track.name);
      accumulator[1].push(track.popularity);
      accumulator[2].push(track.uri);

      return accumulator;
    }, [[], [], []]);

  const unsortedPlaylist = tracksFeatures.map((features, index) => ({
    name: tracksNamesArr[index],
    popularity: tracksPopularity[index],
    tempo: features?.tempo || 0,
    dance: features?.danceability || 0,
    energy: features?.energy || 0,
    happy: features?.valence || 0,
    trackUris: trackUris[index]
  }));

  return {
    unsortedPlaylist,
    ...generateChartArraysWith(unsortedPlaylist)
  };
};

export const generateChartArraysWith = unsortedPlaylist => {
  const [
    tracksNamesArr, popularityArr, tempoArr, danceArr, energyArr, happyArr
  ] = unsortedPlaylist.reduce((accumulator, track) => {
    const { name, popularity, tempo, dance, energy, happy } = track;
    accumulator[0].push(name);
    accumulator[1].push(popularity * 1);
    accumulator[2].push(tempo * 1);
    accumulator[3].push(dance * 100);
    accumulator[4].push(energy * 100);
    accumulator[5].push(happy * 100);

    return accumulator;
  }, [[], [], [], [], [], []]);
  return {
    tracksNamesArr,
    popularityArr,
    tempoArr,
    danceArr,
    energyArr,
    happyArr
  }
};
